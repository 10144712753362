import "core-js/modules/es.array.push.js";
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import { CountDown } from 'vant';
import * as api from '@/api';
export default {
  props: {
    lang_list: {},
    lang: {},
    type: 0,
    dsq: 0,
    time: 0
  },
  data() {
    return {
      search_value: "",
      not: 'Login,login,Registeremail,registeremail,language,Language,line,Line'
    };
  },
  computed: {
    routeName: function () {
      console.log(this.$route.name);
      return this.$route.name;
    }
  },
  methods: {
    notto() {
      showFailToast(this.lang.tp102);
    },
    onfinish() {
      window.location.reload();
    },
    to: function (id1) {
      let _this = this;
      _this.$router.push({
        name: id1,
        query: {
          id: 0
        }
      });
    },
    xun: function (id1) {
      api.all('/api/index/xun', {
        id: id1
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          // this.to = 1
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    },
    goto: function (name) {
      let _this = this;
      _this.$router.push({
        name: name,
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    shou: function (id1) {
      api.all('/api/index/shou', {
        id: id1
      }, (err, data) => {
        if (!err && data.code === 1) {
          showSuccessToast(data.msg);
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (!err && data.code === 0) {
          showToast(data.msg);
        } else {
          console.log(data);
        }
      });
    }
  }
};